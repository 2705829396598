<template>
  <v-app id="mentions-legales">
    <router-link to="/"><i class="fa-solid fa-arrow-left fa-3x return"></i></router-link>

    <div class="text-center pa-2">
      <div id="Inner">
        <h1>Mentions Légales</h1>

        <div id="Content">
          <div class="boxed">
            <div id="lipsum">
              <p>
                <h4>Identification de la société</h4>
                <br>Nom de la société : <b>Christian Manzala</b>
                <br>Adresse : Paris, France
                <br>Email : contact@christianmanzala.eu
              </p>
              <p>
                <h4>Directeur de la publication</h4>
                <br>Le directeur de la publication du site est Christian MANZALA.
              </p>
              <p>
                <h4>Hébergement du site</h4>
                <br>Le site est hébergé par LWS (www.lws.fr - Ligne Web Services), 10, RUE PENTHIEVRE, 75008 PARIS, FRANCE.
              </p>
              <p>
                <h4>Propriété intellectuelle</h4>
                <br>Tous les éléments du site, y compris les textes, images, chartes graphiques, logos, icônes, sons, logiciels, sont la propriété exclusive de <b>Christian Manzala</b> et sont protégés par les lois françaises et internationales relatives à la propriété intellectuelle.
                <br>Toute reproduction, représentation, utilisation, adaptation, modification, incorporation, traduction, commercialisation, partielles ou intégrales du site ou de son contenu (textes, images, chartes graphiques, logos, icônes, sons, logiciels, etc.) par quelque procédé que ce soit et sur quelque support que ce soit, sans autorisation écrite préalable de <b>Christian Manzala</b>, est strictement interdite.
              </p>
              <p>
                <h4>Limitation de responsabilité</h4>
                <br>Les informations contenues sur le site sont fournies en l'état et sont susceptibles d'être modifiées sans préavis. <b>Christian Manzala</b> ne garantit pas l'exactitude, la complétude, l'actualité des informations diffusées sur son site.
                <br>En conséquence, <b>Christian Manzala</b> ne pourra être tenu responsable des dommages directs ou indirects, tels que, sans que cette liste soit exhaustive, pertes financières, pertes de clientèle, manque à gagner, préjudice commercial ou autres, qui pourraient découler de l'utilisation du site ou de l'impossibilité d'y accéder.
              </p>
              <p>
                <h4>Liens hypertextes</h4>
                <br>Le site peut contenir des liens hypertextes vers d'autres sites. <b>Christian Manzala</b> ne peut être tenu responsable du contenu de ces sites et de l'utilisation qui pourra en être faite par les utilisateurs.
              </p>
              <p>
                <h4>Données personnelles</h4>
                <br>Conformément à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, les informations à caractère nominatif relatives aux utilisateurs pourront être utilisées par <b>Christian Manzala</b>
              </p>
              <p>
                <h4>Crédits</h4>
                <!-- <h5>Photographe(s)</h5>
                <br>Stéphane Exer
                <br>
                <h5>Apparitions photographiques</h5>
                <br>Cephas Adda 
                <br>Allison Pineau 
                <br>Sandrine Gruda
                <br>Nidhya Paliakara
                <br>Keiera Douglas
                <br>
                <br> -->
                <br>Site web créé par Randy MBIYA - <a href="https://randymbiya.fr">https://randymbiya.fr</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <small class="text-center">Christian Manzala. Tous droits réservés.</small>
  </v-app>
</template>

<script>
  export default {
    name: 'MentionsLegales',

    metaInfo: {
      title: 'Mentions légales | Christian Manzala',
      meta: [
        { property: 'og:title', content: 'Mentions légales | Christian Manzala' }
      ]
    },

    created() {
      window.scrollTo(0,0);
    },
  }
</script>

<style>
  .return {
    color: black;
    position: inherit;
    margin: 10px;
  }

  #Outer {
    text-align: center;
  }

  #Inner {
    width: 960px;
    margin: 0 auto;
  }

  #Content {
    position: relative;
  }

  .boxed {
    clear: both;
    margin: 10px 3% 10px 3%;
  }

  #lipsum {
    text-align: justify;
  }

  @media screen and (max-width: 980px){
    #Inner {
      width: 100%;
    }
  }
</style>