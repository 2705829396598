<template>
    <div class="middle">
        <div class="content">
            <img src="../assets/christianmanzala2.svg" style="max-width: 350px;" alt="Logo">
            <p style="margin: 25px;">J'arrive très bientôt. <br> Je vous remercie pour votre patience !</p>
        </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'Maintenance'
  };
  </script>
  
  <style scoped>
    .middle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-image: url(../assets/manzala.webp);
        background-position: center;
        background-size: cover;
        color: white;
    }

    .content {
        text-align: center;
    }
  </style>