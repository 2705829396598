<template>
    <v-footer dark padless>
        <v-card flat tile class="white black--text text-center" style="width: 100%">
            <v-divider light></v-divider>

            <v-card-text class="black--text pt-4">
                <h2>Suivez nous</h2>
            </v-card-text>
            
            <v-card-text>
                <v-btn v-for="icon in icons" :key="icon" class="mx-4 black--text" icon>
                    <a class="text-decoration-none black--text" :href="`${icon.link}`">
                        <v-icon size="24px">
                            {{ icon.icon }}
                        </v-icon>
                    </a>
                </v-btn>
            </v-card-text>

            <v-divider light></v-divider>

            <v-row justify="center" no-gutters>
                <v-btn color="white" text rounded class="my-2">
                    <router-link class="text-decoration-none black--text" to="/lookbook">Lookbook</router-link>
                </v-btn>

                <v-btn color="white" text rounded class="my-2">
                    <router-link class="text-decoration-none black--text" to="/notre-histoire">Notre histoire</router-link>
                </v-btn>
                
                <v-btn v-for="alink in links" :key="alink" color="white" text rounded class="my-2">
                    <a class="text-decoration-none black--text" :href="`${alink.link}`">{{ alink.alink }}</a>
                </v-btn>

                <v-btn color="white" text rounded class="my-2">
                    <router-link class="text-decoration-none black--text" to="/mentions-legales">Mentions Légales</router-link>
                </v-btn>
            </v-row>

            <v-divider light></v-divider>

            <v-card-text class="black--text">
                {{ new Date().getFullYear() }} — <strong>Christian Manzala. Tous droits réservés.</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>
  
<script>
  export default {
    data: () => ({
      icons: [
        {icon:'mdi-youtube', link:'https://www.youtube.com/@christianmanzala'},
        {icon:'mdi-facebook', link:'https://www.facebook.com/profile.php?id=100088888763182'},
        {icon:'mdi-twitter', link:''},
        {icon:'mdi-linkedin', link:'https://fr.linkedin.com/in/christian-manzala-179383149'},
        {icon:'mdi-instagram', link:'https://www.instagram.com/christianmanzalaofficial/'},
      ],

      links: [
        {alink:'Boutique', link:'#'},
        {alink:'Ils nous font confiance', link:'#customer'},
        {alink:'Contact', link:'#contact'},
      ],
    }),
  }
</script>
  
<style>

</style>