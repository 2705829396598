<template>
  <!-- <v-app id="Lookbook" class="full-height bg lookbook-bg" style="background-color:black">
    <router-link to="/"><i class="fa-solid fa-arrow-left fa-3x return2"></i></router-link>

    <div class="d-flex flex-column justify-top" style="height: 100%">
      <div class="pa-5 hover">
        <router-link class="text-decoration-none white--text z-index97" to="/lookbook/creation-2023">CRÉATION 2023</router-link>
      </div>

      <div class="pa-5 hover">
        <router-link class="text-decoration-none white--text z-index97" to="/lookbook/personnalite">CRÉATION PERSONNALITÉS</router-link>
      </div>
      
      <div class="pa-5 hover">
        <router-link class="text-decoration-none white--text z-index97" to="/lookbook/stylisme">CRÉATION STYLISME</router-link>
      </div>
    </div>
  </v-app> -->

  <v-app id="Lookbook" class="full-height bg lookbook2-bg" style="background-color:black">
    <router-link to="/"><i class="fa-solid fa-arrow-left fa-3x return3 hover-return"></i></router-link>
    
    <div class="gallery">
      <img v-for="image in images" :key="image.id" :src="image.src" loading="lazy" @click="showImage(image.src)" class="thumbnail z-index97"/>
    </div>

    
      <div @click="closeLightbox" class="lightbox text-center" :class="{ 'lightbox-zoomed': zoomed }" v-if="showLightbox">
        <div @click="closeLightbox" class="closeLightbox"><i class="fa-solid fa-xmark fa-3x return3 hover-return z-index97"></i></div>

        <img @click.stop="toggleZoom" :src="selectedImage" :class="{ 'zoomed': zoomed }" />

        <div class="lightboxNavigation text-center" style="z-index: 99; width: 100%">
          <button v-if="!isFirstImage" @click.stop @click="prevImage" class="text-h4 white hoverColor" style="padding: 10px; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"></button>
          <button v-if="isFirstImage" @click.stop @click="prevImage"></button>
          <button v-if="!isLastImage" @click.stop @click="nextImage" class="text-h4 white hoverColor" style="padding: 10px; border-top-left-radius: 5px; border-bottom-left-radius: 5px;"></button>
          <button v-if="isLastImage" @click.stop @click="nextImage"></button>
        </div>
      </div>
  </v-app>
</template>

<!-- <script>
  export default {
    name: 'Lookbook',

    // metaInfo: {
    //   title: 'Lookbook | Christian Manzala',
    //   meta: [
    //     { name: 'description', content: 'CRÉATION 2023 | CRÉATION PERSONNALITÉ | CRÉATION STYLISME ... Découvrez le lookbook officiel des collection Christian Manzala.' },
    //     { property: 'og:title', content: 'Lookbook | Christian Manzala' }
    //   ]
    // },
    metaInfo: {
      title: 'Défilé "New Breath" 06/2024 | Christian Manzala',
      meta: [
        { name: 'description', content: 'Découvrez les images officiel de la collection "New Breath" de Christian Manzala.' },
        { property: 'og:title', content: 'Défilé "New Breath" | Christian Manzala' }
      ]
    },

    created() {
      window.scrollTo(0,0);
    },

  }
</script> -->

<script>
  export default {
    name: 'Creation-2023',

    metaInfo: {
      title: 'Défilé "New Breath" - Nouvelle collection 2024 | Christian Manzala',
      meta: [
        { property: 'og:title', content: 'Défilé "New Breath" - Lookbook Nouvelle collection 2024 | Christian Manzala' }
      ]
    },

    data() {
      return {
        images: [
          { id: 2, src: require("../assets/pics/SHOW/fff-794.webp") },
          { id: 3, src: require("../assets/pics/SHOW/fff-795.webp") },
          { id: 4, src: require("../assets/pics/SHOW/fff-796.webp") },
          { id: 5, src: require("../assets/pics/SHOW/fff-797.webp") },
          { id: 6, src: require("../assets/pics/SHOW/fff-819.webp") },
          { id: 7, src: require("../assets/pics/SHOW/fff-820.webp") },
          { id: 8, src: require("../assets/pics/SHOW/fff-821.webp") },
          { id: 9, src: require("../assets/pics/SHOW/fff-822.webp") },
          { id: 10, src: require("../assets/pics/SHOW/fff-823.webp") },
          { id: 11, src: require("../assets/pics/SHOW/fff-824.webp") },
          { id: 12, src: require("../assets/pics/SHOW/fff-825.webp") },
          { id: 13, src: require("../assets/pics/SHOW/fff-826.webp") },
          { id: 14, src: require("../assets/pics/SHOW/fff-827.webp") },
          { id: 15, src: require("../assets/pics/SHOW/fff-828.webp") },
          { id: 16, src: require("../assets/pics/SHOW/fff-829.webp") },
          { id: 17, src: require("../assets/pics/SHOW/fff-830.webp") },
          { id: 18, src: require("../assets/pics/SHOW/fff-831.webp") },
          { id: 19, src: require("../assets/pics/SHOW/fff-832.webp") },
          { id: 20, src: require("../assets/pics/SHOW/fff-833.webp") },
          { id: 21, src: require("../assets/pics/SHOW/fff-834.webp") },
          { id: 22, src: require("../assets/pics/SHOW/fff-835.webp") },
          { id: 23, src: require("../assets/pics/SHOW/fff-836.webp") },
          { id: 24, src: require("../assets/pics/SHOW/fff-837.webp") },
          { id: 25, src: require("../assets/pics/SHOW/fff-838.webp") },
          { id: 28, src: require("../assets/pics/SHOW/fff-841.webp") },
          { id: 29, src: require("../assets/pics/SHOW/fff-842.webp") },
          { id: 30, src: require("../assets/pics/SHOW/fff-843.webp") },
          { id: 31, src: require("../assets/pics/SHOW/fff-844.webp") },
          { id: 32, src: require("../assets/pics/SHOW/fff-845.webp") },
          { id: 33, src: require("../assets/pics/SHOW/fff-846.webp") },
          { id: 34, src: require("../assets/pics/SHOW/fff-847.webp") },
          { id: 35, src: require("../assets/pics/SHOW/fff-848.webp") },
          { id: 36, src: require("../assets/pics/SHOW/fff-849.webp") },
          { id: 37, src: require("../assets/pics/SHOW/fff-850.webp") },
          { id: 38, src: require("../assets/pics/SHOW/fff-851.webp") },
          { id: 39, src: require("../assets/pics/SHOW/fff-852.webp") },
          { id: 40, src: require("../assets/pics/SHOW/fff-853.webp") },
          { id: 41, src: require("../assets/pics/SHOW/fff-854.webp") },
          { id: 42, src: require("../assets/pics/SHOW/fff-855.webp") },
          { id: 43, src: require("../assets/pics/SHOW/fff-856.webp") },
          { id: 44, src: require("../assets/pics/SHOW/fff-857.webp") },
          { id: 45, src: require("../assets/pics/SHOW/fff-858.webp") },
          { id: 46, src: require("../assets/pics/SHOW/fff-859.webp") },
          { id: 47, src: require("../assets/pics/SHOW/fff-860.webp") },
          { id: 48, src: require("../assets/pics/SHOW/fff-861.webp") },
          { id: 49, src: require("../assets/pics/SHOW/fff-862.webp") },
          { id: 50, src: require("../assets/pics/SHOW/fff-863.webp") },
          { id: 51, src: require("../assets/pics/SHOW/fff-864.webp") },
          { id: 52, src: require("../assets/pics/SHOW/fff-865.webp") },
          { id: 53, src: require("../assets/pics/SHOW/fff-866.webp") },
          { id: 54, src: require("../assets/pics/SHOW/fff-867.webp") },
          { id: 55, src: require("../assets/pics/SHOW/fff-868.webp") },
          { id: 56, src: require("../assets/pics/SHOW/fff-869.webp") },
          { id: 57, src: require("../assets/pics/SHOW/fff-870.webp") },
          { id: 58, src: require("../assets/pics/SHOW/fff-871.webp") },
          { id: 59, src: require("../assets/pics/SHOW/fff-872.webp") },
          { id: 60, src: require("../assets/pics/SHOW/fff-873.webp") },
          { id: 61, src: require("../assets/pics/SHOW/fff-874.webp") },
          { id: 62, src: require("../assets/pics/SHOW/fff-875.webp") },
          { id: 63, src: require("../assets/pics/SHOW/fff-876.webp") },
          { id: 64, src: require("../assets/pics/SHOW/fff-877.webp") },
          { id: 65, src: require("../assets/pics/SHOW/fff-878.webp") },
          { id: 66, src: require("../assets/pics/SHOW/fff-879.webp") },
          { id: 67, src: require("../assets/pics/SHOW/fff-880.webp") },
          { id: 68, src: require("../assets/pics/SHOW/fff-881.webp") },
          { id: 69, src: require("../assets/pics/SHOW/fff-882.webp") },
          { id: 70, src: require("../assets/pics/SHOW/fff-883.webp") },
          { id: 71, src: require("../assets/pics/SHOW/fff-884.webp") },
          { id: 72, src: require("../assets/pics/SHOW/fff-885.webp") },
          { id: 73, src: require("../assets/pics/SHOW/fff-886.webp") },
          { id: 74, src: require("../assets/pics/SHOW/fff-887.webp") },
        ],

        showLightbox: false,
        selectedImage: '',

        zoomed: false,
      }
    },

    methods: {
      showImage(src) {
        this.selectedImage = src
        this.showLightbox = true
      },

      closeLightbox() {
        this.showLightbox = false
      },

      nextImage() {
        const currentIndex = this.images.findIndex(image => image.src === this.selectedImage);
        if (currentIndex !== -1 && currentIndex < this.images.length - 1) {
          this.selectedImage = this.images[currentIndex + 1].src;
        }
      },

      prevImage() {
        const currentIndex = this.images.findIndex(image => image.src === this.selectedImage);
        if (currentIndex > 0) {
          this.selectedImage = this.images[currentIndex - 1].src;
        }
      },

      showImage(src) {
        this.selectedImage = src;
        this.showLightbox = true;
        this.zoomed = false;
      },

      toggleZoom() {
        this.zoomed = !this.zoomed;
      },
    },

    computed: {
      isFirstImage() {
        const currentIndex = this.images.findIndex(image => image.src === this.selectedImage);
        return currentIndex === 0;
      },

      isLastImage() {
        const currentIndex = this.images.findIndex(image => image.src === this.selectedImage);
        return currentIndex === this.images.length - 1;
      }
    },
  }
</script>

<style>
  .return2 {
    color: white;
    z-index: 99;
    margin: 10px;
  }

  .return3 {
    color: white;
    z-index: 99;
    margin: 10px;
    transition: 0.1s;
  }

  .hover {
    transition: 0.1s;
  }

  .hover:hover {
    font-size: xxx-large;
    transition: 0.3s;
  }

  /* .lookbook2-bg::before{
    content: '';
    background-image: url("assets/pics/IMG_9391.jpeg");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.15;
  } */






  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .thumbnail {
    height: 500px;
    padding: 10px;
    cursor: pointer;
  }

  .lightbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 98;
    max-height: 100%;
  }

  .lightbox img {
    cursor: zoom-in;
    max-height: 100%;
  }

  .lightbox img.zoomed {
    cursor: zoom-out;
    width: 100%;
    max-height: none;
    transition: transform 0.5s;
  }

  .lightbox.lightbox-zoomed {
    overflow: auto;
  }

  .lightboxNavigation {
    position: fixed;
    z-index: 99;
    bottom: 50%;
    display: flex;
    justify-content: space-between;
    opacity: 0.6;
  }

  .closeLightbox {
    cursor: pointer;
    z-index: 99;
    position: fixed;
    right: 0;
    top: 0;
    margin-top: -5px;
  }

  .hoverColor:hover {
    cursor: pointer;
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
    transition: background-image 2s;
    background-size:cover;
    -webkit-animation: slidein 25s;
    animation: slidein 25s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }

  @-webkit-keyframes slidein {
    from {background-position: top; background-size:3000px; }
    to {background-position: -100px 0px;background-size:2750px;}
  }

  @keyframes slidein {
    from {background-position: top;background-size:3000px; }
    to {background-position: -100px 0px;background-size:2750px;}
  }
</style>