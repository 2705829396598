<template>
  <v-app id="notre-histoire">
    <router-link to="/"><i class="fa-solid fa-arrow-left fa-3x return"></i></router-link>

    <div class="text-center pa-2">
      <div id="Inner">
        <h1>Notre histore</h1>
        <h4>"Sport Couture"</h4>
        <h5>"La mode, j’en suis tombé amoureux, elle est venue à moi"</h5>
        <br/>

        <hr>

        <div id="Content">
          <div class="boxed">
            <div id="lipsum">
              <p>
                Christian Manzala est un créateur de mode franco congolais, connu pour son style audacieux et avant-gardiste qui défie les conventions de la mode. Il est né à Kinshasa en République Démocratique du Congo et a grandit à Paris, où il a développé une passion  pour la création de vêtements en autodidacte.
              </p>
              <p>
                Christian a commencé sa carrière en travaillant pour diverses marques et a même dirigé la marque européenne "Christian Audigier" durant deux années. Grâce à sa vision unique et à son style créatif, Christian a rapidement émergé comme un créateur de mode innovant, créant des looks à partir des inspirations qui lui viennent lorsqu’il voit et touche les tissus.
              </p>
              <p>
                Avec une prédilection pour les matières uniques, les coupes inattendues et les détails complexes, Christian dit avant tout vouloir casser les codes en ayant pour slogan le "Sport couture".
              </p>
              <p>
                En plus de son travail de création, Christian est également un défenseur passionné de la durabilité dans la mode. Il travaille sans relâche pour promouvoir des pratiques éthiques et respectueuses de l'environnement dans l'industrie de la mode, en utilisant des matières durables et en concevant des vêtements qui sont à la fois beaux et écologiques.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <small class="text-center">Christian Manzala. Tous droits réservés.</small>
  </v-app>
</template>

<script>
  export default {
    name: 'AboutView',

    metaInfo: {
      title: 'Notre histoire | Christian Manzala',
      meta: [
        { property: 'og:title', content: 'Notre histoire | Christian Manzala' }
      ]
    },

    created() {
      window.scrollTo(0,0);
    },
  }
</script>

<style>
  .return {
    color: black;
    position: inherit;
    margin: 10px;
  }

  #Outer {
    text-align: center;
  }

  #Inner {
    width: 960px;
    margin: 0 auto;
  }

  #Content {
    position: relative;
  }

  .boxed {
    clear: both;
    margin: 10px 3% 10px 3%;
  }

  #lipsum {
    text-align: justify;
  }

  @media screen and (max-width: 980px){
    #Inner {
      width: 100%;
    }
  }
</style>