<template>
    <v-container fluid class="not-found bg about-bg">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6>
          <v-card class="pa-3 text-center">
            <v-icon large class="mb-3">fas fa-exclamation-triangle</v-icon>
            <h1 class="mb-3">Erreur 404 - Page non trouvée</h1>
            <p>La page que vous cherchez n’existe pas.</p>
            <br>
            <div style="width: 100%;" class="d-flex justify-center">
              <router-link to="/" class="text-decoration-none home-link">
                <v-img src="../assets/christianmanzala.svg" style="max-width: 180px" cover class="hover-return"></v-img>
                <small style="color: black">Retourver vers la page d'accueil</small>
              </router-link>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'NotFoundComponent'
  }
  </script>
  
  <style scoped>
  .not-found {
    height: 100vh;
    background-color: #f3f3f3;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  }

  .home-link {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease;
  }

  .home-link:hover::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
    transition: all 0.3s ease;
    transform: scale(1.2);
  }

  .home-link:hover {
    color: #fff;
    transform: scale(1.1);
  }
  </style>