import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Lookbook from '../views/Lookbook.vue'
// import Creation2023 from '../views/lookbook/Creation2023.vue'
// import Stylisme from '../views/lookbook/Stylisme.vue'
// import Personnalite from '../views/lookbook/Personnalite.vue'
// import Paris92 from '../views/lookbook/Paris92.vue'
import AboutView from '../views/AboutView.vue'
import MentionsLegales from '../views/MentionsLegales.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import MaintenancePage from '@/views/MaintenancePage.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/m',
      name: 'Maintenance',
      component: MaintenancePage
    },
    {
      path: '*',
      redirect: '/m'
    },
    {
      path: '/lookbook',
      name: 'lookbook',
      component: Lookbook,
    },
    // {
    //   path: '/lookbook/creation-2023',
    //   name: 'lookbook-creation-2023',
    //   component: Creation2023
    // },
    // {
    //   path: '/lookbook/stylisme',
    //   name: 'lookbook-stylisme',
    //   component: Stylisme
    // },
    // {
    //   path: '/lookbook/personnalite',
    //   name: 'lookbook-personnalite',
    //   component: Personnalite
    // },
    // {
    //   path: '/lookbook/paris92',
    //   name: 'lookbook-paris92',
    //   component: Paris92
    // },
    {
      path: '/notre-histoire',
      name: 'notre-histoire',
      component: AboutView
    },
    {
      path: '/mentions-legales',
      name: 'mentions-legales',
      component: MentionsLegales
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFoundComponent
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/',
      name: 'home',
      component: HomeView
    }
  ]
});


// Ce code génère un fichier sitemap.xml avec une liste d'URL. Copier et coller ensuite le contenu du sitemap généré dans un fichier sitemap.xml dans votre dossier public

const builder = require('xmlbuilder');
const routes = ['/', '/lookbook','/lookbook/collection-voyage','/lookbook/stylisme','/lookbook/personnalite','/notre-histoire','/mentions-legales'];

let xml = builder.create('urlset', { version: '1.0', encoding: 'UTF-8' })
  .att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9');

routes.forEach(route => {
  xml = xml.ele('url')
    .ele('loc', `https://christianmanzala.com${route}`)
    .up();
});

const sitemap = xml.end({ pretty: true });

console.log(sitemap);