<template>
    <div>
        <v-navigation-drawer v-model="drawer" app>
            <div class="sidebar-close" @click="drawer = false">
                <i class="fas fa-times"></i>
            </div>

            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="deep-yellow--text text--accent-4" >
                    <router-link class="text-decoration-none" to="/lookbook">
                        <v-list-item>
                            <v-list-item-title class="text-center">Lookbook</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link class="text-decoration-none" to="/notre-histoire">
                        <v-list-item>
                            <v-list-item-title class="text-center">Notre histoire</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <v-list-item @click="scroll('customer'); drawer = !drawer;">
                        <v-list-item-title class="text-center">Ils nous font confiance</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="scroll('contact'); drawer = !drawer;">
                        <v-list-item-title class="text-center">Contact</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar :color="color" :elevation="elevation" fixed :scroll-target="scrollTarget">
            <div v-if="!isScrolled">
                <v-app-bar-nav-icon style="color: white" @click="drawer = !drawer" />
            </div>

            <div v-if="isScrolled">
                <v-app-bar-nav-icon @click="drawer = !drawer" />
            </div>

            <div v-if="!isScrolled" style="width: 100%;" class="d-flex justify-center">
                <v-img src="../assets/christianmanzala2.svg" style="max-width: 230px" cover></v-img>
            </div>

            <div v-if="isScrolled" style="width: 100%;" class="d-flex justify-center">
                <v-img src="../assets/christianmanzala.svg" style="max-width: 230px" cover></v-img>
            </div>

            <div v-if="!isScrolled" class="d-flex justify-right">
                <a href="#" class="text-decoration-none" style="color: white">BOUTIQUE</a>
            </div>

            <div v-if="isScrolled" class="d-flex justify-right">
                <a href="#" class="text-decoration-none" style="color: black">BOUTIQUE</a>
            </div>
        </v-app-bar>
    </div>
</template>
  
<script>
  export default {
    data() {
      return {
        elevation: 0,
        color: 'transparent',
        scrollTarget: null,
        isScrolled: false,
        drawer: false,
        group: null,
        clipped: true,
        disableRouteWatcher: true,
        miniVariant: false,
        miniVariantWidth: 80,
        permanent: false,
        temporary: false,
      }
    },
  
    methods: {
        onScroll() {
            this.isScrolled = window.scrollY > 0;
            if (this.isScrolled) {
                this.elevation = 4;
                this.color = 'white';
            } else {
                this.elevation = 0;
                this.color = 'transparent';
            }
        },

        scroll(refName) {
            const element = document.getElementById(refName);
            element.scrollIntoView({behavior: "smooth"});
        },
    },
  
    mounted() {
      this.scrollTarget = window;
      window.addEventListener('scroll', this.onScroll);
    },
  
    destroyed() {
      window.removeEventListener('scroll', this.onScroll);
    },
  }
</script>

<style scoped>
.sidebar-close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    cursor: pointer;
    color: #000;
    font-size: 20px;
    transition: color 0.3s ease;
}

.sidebar-close:hover {
    color: #999;
}
</style>