<template>
  <v-app id="home">
    <div v-if="isLoading" id="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <v-img src="../assets/christianmanzala.svg" style="max-width: 230px; position: absolute; bottom: 0px" class="mb-10" cover></v-img>
    </div>
    <NavBar class="z-index99"/>
    <v-container fluid>
        <v-row class="bg">
          <v-col class="full-height" style="padding: 0;">
            <video id="video-background" autoplay muted loop playsinline>
              <source src="../assets/Chr background sample.mp4" type="video/mp4">
              <source src="../assets/Chr background sample.webm" type="video/webm">
              
              Your browser does not support the video tag.
            </video>
          </v-col>
        </v-row>

      <router-link class="text-decoration-none white--text" to="/lookbook">
        <v-row class="bg lookbook-bg" style="background-color: black" id="lookbook">
          <v-col class="full-height d-flex justify-center align-center">
            <h1 class="z-index97">DÉFILÉ "New Breath"</h1>
          </v-col>
        </v-row>
      </router-link>

      <v-row class="full-height bg customer-bg d-flex align-content-end" style="background-color: black" id="customer">
        <v-col class="white--text d-flex flex-column">
          <div>
            <h1 class="white--text text-center pa-5 z-index97">Ils nous font confiance</h1>
          </div>

          <div class="d-flex flex-wrap justify-space-around "> 
             <router-link to="/lookbook/Paris92"><v-img contain max-width="100" src="../assets/Paris_92.svg" class="ma-5 hover"></v-img></router-link>
          </div>
        </v-col>
      </v-row>

      <v-row id="contact" class="pt-5 pb-5">
        <v-col style="text-align: center">
          <div class="child">
            <h1>Contact</h1>
            <br>
            <h2>Informations</h2>
            <span class="text-caption">contact@christianmanzala.eu</span><br><br/>
            <h2>Création sur mesure</h2>
            <span class="text-caption">showroom@christianmanzala.eu</span><br><br/>
            <h2>Service après vente</h2>
            <span class="text-caption">sav@christianmanzala.eu</span>
          </div>
        </v-col>
        
        <v-col cols="12" sm="8">
          <form name="form" ref="form" @submit.prevent="sendEmail">
            <select id="emails" name="subject">
              <option value="" disabled selected>Sélectionnez le sujet ▼</option>
              <option value="contact@christianmanzala.eu">Informations</option>
              <option value="showroom@christianmanzala.eu">Création sur mesure</option>
              <option value="sav@christianmanzala.eu">Service après vente</option>
            </select>
            
            <input type="text" name="user_name" placeholder="Votre nom">
            
            <input type="email" name="user_email" placeholder="Votre adresse email">

            <input type="tel" name="user_phone" placeholder="Votre numéro de téléphone">
            
            <textarea name="message" cols="30" rows="5" placeholder="Message..."></textarea>
            
            <input type="submit" value="Envoyer" class="hover-submit">
          </form>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog_success" transition="dialog-bottom-transition" max-width="600">
      <v-card>

        <v-toolbar color="green" dark><span style="width: 100%" class="text-center">Message bien transmis !</span></v-toolbar>

        <v-card-text class="text-center">
          <div class="text-h3 pa-12">Merci de nous avoir contacté. Nous vous répondrons dans les meilleurs délais !</div>
        </v-card-text>

        <v-card-actions class="justify-center text-center">
          <v-btn text @click="dialog_success = false; reloadPage()">Fermer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_failure" transition="dialog-bottom-transition" max-width="600">
      <v-card>

        <v-toolbar color="black" dark><span style="width: 100%" class="text-center">Erreur !</span></v-toolbar>

        <v-card-text class="text-center">
          <div class="text-h3 pa-12">La transmission du message a echouée.</div>
          <br>
          Veuillez compléter tous les champs: Sujet, Votre Nom, Votre Email & Message
        </v-card-text>

        <v-card-actions class="justify-center text-center">
          <v-btn text @click="dialog_failure = false;">Fermer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_failure_api" transition="dialog-bottom-transition" max-width="600">
      <v-card>

        <v-toolbar color="red" dark><span style="width: 100%" class="text-center">Erreur !</span></v-toolbar>

        <v-card-text class="text-center">
          <div class="text-h3 pa-12 text-center">La transmission du message a echouée.</div>
          <br>
          Un problème est survenu, merci de réessayer plus tard.
        </v-card-text>

        <v-card-actions class="justify-center text-center">
          <v-btn text @click="dialog_failure_api = false;">Fermer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <FooterView />
  </v-app>
</template>

<script>
  import NavBar from '@/components/NavBar.vue';
  import FooterView from '@/components/FooterView.vue';
  import emailjs from '@emailjs/browser';

  export default {
    name: 'HomeView',

    metaInfo: {
      title: 'Christian Manzala | Site officiel',
      meta: [
        { name: 'description', content: 'Découvrez le site officiel de Christian Manzala. Explorez nos créations originales, notre vision artistique et notre parcours professionnel.' },
        { property: 'og:title', content: 'Christian Manzala | Site officiel' }
      ]
    },

    components: {
      NavBar,
      FooterView,
    },

    created() {
      window.scrollTo(0,0);
    },

    data () {
      return {
        loader: "bars",
        dialog_success: false,
        dialog_failure: false,
        dialog_failure_api: false,
        isLoading: true
      }
    },

    methods: {
      sendEmail() {
        var vm = this;
        var name = document.forms["form"]["user_name"].value;
        var email = document.forms["form"]["user_email"].value;
        var phone = document.forms["form"]["user_phone"].value;
        var message = document.forms["form"]["message"].value;
        var subject = document.forms["form"]["subject"].value;

        function isEmptyOrSpaces(str){
            return str === null || str.match(/^ *$/) !== null;
        }

        if (isEmptyOrSpaces(name)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(email)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(phone)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(message)) {
          vm.dialog_failure = true;
        }

        else if (isEmptyOrSpaces(subject)) {
          vm.dialog_failure = true;
        }

        else if (subject == 'contact@christianmanzala.eu') {
          let LoadingContainer = this.$refs.LoadingContainer;
          let loader = this.$loading.show(
            {
              container: LoadingContainer,
              loader: this.loader,
            }
          );

          emailjs.sendForm('service_3lps2jd', 'template_rlvb4lr', this.$refs.form, 'UhyIx9hBMD1K8zChR')
          .then((result) => {
              console.log('SUCCESS!', result.text);
              vm.dialog_success = true;
              loader.hide();
          }, (error) => {
              console.log('FAILED...', error.text);
              vm.dialog_failure_api = true;
              loader.hide();
          });
          
          
        }

        else if (subject == 'showroom@christianmanzala.eu') {
          let LoadingContainer = this.$refs.LoadingContainer;
          
          let loader = this.$loading.show(
            {
              container: LoadingContainer,
              loader: this.loader,
            }
          );

          emailjs.sendForm('service_ry5avdw', 'template_8foimg9', this.$refs.form, 'UhyIx9hBMD1K8zChR')
          .then((result) => {
              console.log('SUCCESS!', result.text);
              vm.dialog_success = true;
              loader.hide();
          }, (error) => {
              console.log('FAILED...', error.text);
              vm.dialog_failure_api = true;
              loader.hide();
          });

          
        }

        else if (subject == 'sav@christianmanzala.eu') {
          let LoadingContainer = this.$refs.LoadingContainer;
          
          let loader = this.$loading.show(
            {
              container: LoadingContainer,
              loader: this.loader,
            }
          );

          emailjs.sendForm('service_ry5avdw', 'template_8foimg9', this.$refs.form, 'UhyIx9hBMD1K8zChR')
          .then((result) => {
              console.log('SUCCESS!', result.text);
              vm.dialog_success = true;
              loader.hide();
          }, (error) => {
              console.log('FAILED...', error.text);
              vm.dialog_failure_api = true;
              loader.hide();
          });
        }
      },

      reloadPage() {
        window.location.reload();
      },
    },

    mounted() {
      window.addEventListener('load', () => {
        this.isLoading = false;
      });
    },
  }
</script>

<style>
  #loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
  }

  .full-height {
    min-height: 100vh;
    touch-action: manipulation;
  }

  .bg {
    position: relative;
  }

  .home-bg::before{
      content: '';
      /* background-image: url("../assets/Chr background sample.webp");
      background-size: cover;
      background-position: center; */
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
  }

  #video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .lookbook-bg::before{
      content: '';
      background-image: url("../assets/fff-812.webp");
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.80;
    }

    .customer-bg::before{
      content: '';
      background-image: url("../assets/fff-792.webp");
      background-size: cover;
      background-position: bottom;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.80;
    }

    .bg h1 {
      position: relative;
    }

    .child {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      margin-right: 8px;
    }

    .company {
      z-index: 99;
    }

    select {
      width: 100%;
      padding: 12px 20px;
      border: 1px solid rgb(0, 0, 0);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
      color: black;
      cursor: pointer;
    }

    input[type=text], [type=email], textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid rgb(0, 0, 0);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
      color: black;
    }

    input[type=tel] {
      width: 100%;
      padding: 12px;
      border: 1px solid rgb(0, 0, 0);
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
      color: black;
    }

    input[type=submit] {
      background-color: rgb(0, 0, 0);
      color: white;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    .z-index97 {
      z-index: 97
    }

    .z-index98 {
      z-index: 98
    }

    .z-index99 {
      z-index: 98
    }

    .hover-submit {
      transition: 0.2s;
    }

    .hover-submit:hover {
      background-color: goldenrod;
      color: black;
      transition: 0.3s;
    }

    .hover:hover {
      cursor: pointer;
    }
</style>